import { useQuery } from '@tanstack/vue-query';

export interface AllCollections {
	description: string;
	handle: string;
	id: number;
	logo: string;
	meta_description: string;
	meta_title: number;
	title: string;
}

export const fetchAllCollections = async (): Promise<AllCollections[]> => {
	const api = createApiInstance();
	const response = await api.get('/v1/collections');

	if (response.status === 200 && Array.isArray(response.data)) {
		return response.data;
	}

	throw new Error('Invalid response from server');
};

export const useFetchAllCollections = () => {
	const { data, isLoading, suspense, error } = useQuery({
		queryKey: ['all-collections'],
		queryFn: fetchAllCollections,
		refetchOnMount: false,
		staleTime: 30000,
	});
	return { data, isLoading, suspense, error };
};
